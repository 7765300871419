export default {
	init () {
		jQuery(document).ready(function ($) {

			if ($('#give_purchase_submit').length) {

				$('input[name=honor-memory]').change(function () {
					if ($(this).val() === 'yes') {
						$('#give-honor-memory-select-wrap').removeClass('hidden')

						// show gift receipt
						$('#give-receipt-wrap').removeClass('hidden')
						$('#give-receipt').prop('required', true)

					} else {
						if ($('#give-honor-memory-select-wrap').is(':visible')) {
							$('#give-honor-memory-select-wrap').addClass('hidden')
						}

						if ($('#give-honor-wrap').is(':visible')) {
							$('#give-honor-wrap').addClass('hidden')
						}

						if ($('#give-memory-wrap').is(':visible')) {
							$('#give-memory-wrap').addClass('hidden')
						}

						// hide gift receipt
						if ($('#give-receipt-wrap').is(':visible')) {
							$('#give-receipt-wrap').addClass('hidden')
							$('#give-receipt').prop('required', false)
						}

					}

				})

				$('input[name=honor-memory-select]').change(function () {
					if ($(this).val() === 'honor') {
						if ($('#give-memory-wrap').is(':visible')) {
							$('#give-memory-wrap').addClass('hidden')
							$('#give_in_memory_of').prop('required', false)
						}

						$('#give-honor-wrap').removeClass('hidden')
						$('#give_in_honor_of').prop('required', true)

					} else {
						if ($('#give-honor-wrap').is(':visible')) {
							$('#give-honor-wrap').addClass('hidden')
							$('#give_in_honor_of').prop('required', false)
						}

						$('#give-memory-wrap').removeClass('hidden')
						$('#give_in_memory_of').prop('required', true)

						// show gift receipt
						$('#give-receipt-wrap').removeClass('hidden')
						$('#give-receipt').prop('required', true)
					}
				})

				$('input[name=give-receipt]').change(function () {
					if ($(this).val() === 'yes') {
						// show gift address
						$('#give-address').removeClass('hidden')

						$('#give_address_name').prop('required', true)
						$('#give_address_1').prop('required', true)
						$('#give_address_city').prop('required', true)
						$('#give_address_state').prop('required', true)
						$('#give_address_zip').prop('required', true)
					} else {
						if ($('#give-address').is(':visible')) {
							$('#give-address').addClass('hidden')
							$('#give_address_name').prop('required', false)
							$('#give_address_1').prop('required', false)
							$('#give_address_city').prop('required', false)
							$('#give_address_state').prop('required', false)
							$('#give_address_zip').prop('required', false)
						}

					}
				})

			}
		})

	},
	finalize () {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
}
